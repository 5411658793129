export class ProductUM {
  Id;// string || int
  Name;// string
  ShortDescription;// string
  Price;// number
  MSRP; // number
  Images; // array
  Image; // string
  Options; // array
  Specification; // string
  Description; // string
  Tags; // array
  Gift; // array
  ForSale; // boolean
  ConditionalGift; // array
  Type; // string
}

export class ProductImageUM {
  ImageUrl; // string
  VideoUrl; // string
}

export class ProductOptionUM {
  Id; // string
  Name; // string
  Stock; // number
  RealStock; // number
  DisplayStock; // number
}

export class ConditionalGiftUM { // 滿額贈
  Id; // string
  Name; // string
  LowerLimit; // number
}

export class GiftUM { // 下單送
  Id; // string
  Name; // string
}

// * 活動商品主題
export class EventProductUM {
  subCode;// string
  name;// string
  title;// string
  description;// string
  videoLink;// string
  products;// array
}
